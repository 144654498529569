<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container-no-tarbar">
      <div class="margin-bottom-10 margin-top-10 text-center">
        <qrcode-vue :value="inviteUrl" :size="300" level="H" />
        <h4>截图分享好友</h4>
        <h4>邀请好友使用微信扫码二维码</h4>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { getParams } from '../utils/index'
import wxauth from '../mixin/wxauth'

export default {
  mixins: [wxauth],
  name: "promotion",
  components: {
    QrcodeVue
  },
  data () {
    return {
      promotionCode: '', // 用于生成推广二维码邀请
      inviteUrl: '',
    }
  },
  created () {
    this.wxAuthInit()
  },
  mounted () {
  },
  methods: {
    doWxAuthCb () {
      this.initUrlParams()
    },
    // 获取URL上的参数
    initUrlParams () {
      if (getParams('promotionCode')) {
        this.promotionCode = getParams('promotionCode')
        this.initQr()
      } else {
        this.$toast.info('无邀请码');
        setTimeout(() => {
          this.$router.back()
        }, 2000)
      }
    },
    // 初始化二维码
    initQr () {
      const origin = location.origin
      const url = `${origin}/users?inviterCode=${this.promotionCode}`
      this.inviteUrl = url
    }
  },
};
</script>
<style lang="css" scoped>
.public-container-no-tarbar{
  margin: unset;
}
.weui-btn.public-btn-login{
  padding: unset;
}
</style>
